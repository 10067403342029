<template>
  <div class="container">
    <img class="icon" src="@/assets/icon-tips-success.png" alt="" />
    <div class="title">申请成功</div>
    <div class="desc">我们已收到您的申请，请等待 工作人员与您联系</div>
    <div class="button" @click="$router.back()">知道啦</div>
    <div
      v-if="$store.state.fromPage !== 'group-demo'"
      class="bottom-text"
      @click="$router.replace('/questionnaire-business')"
    >
      智能方案设计>
    </div>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {
    this.$setTitle("申请成功");
    this.$store.commit("setTitle", "申请成功");
  },
  methods: {},
};
</script>
  
  <style>
html body {
  background-color: #f4f4f4;
}
</style>
  
  <style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 90px;
}

.icon {
  margin-top: 160px;
  width: 70px;
  height: 70px;
}

.title {
  margin-top: 20px;
  font-size: 18px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #2c2f37;
  line-height: 21px;
}

.desc {
  margin-top: 10px;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #878d99;
  line-height: 16px;
  text-align: center;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  width: 150px;
  height: 40px;
  border-radius: 20px;
  border: 1px solid #00c4b3;
  font-size: 16px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #00c4b3;
}

.bottom-text {
  margin-top: 20px;
  font-size: 14px;
  color: #00c4b3;
}
</style>